<template>
  <section class="modal-simple">
    <section class="modal-simple__bg" @click="close"></section>
    <section class="modal-simple__content">
      <img
        class="modal-simple__background"
        src="@/assets/images/modal-background.jpg"
      />
      <section class="info__image">
        <img v-if="!modalGif" src="@/assets/images/temp.gif" alt="" />
        <img v-else :src="modalGif" alt="" />
        <div class="info__image-label">Мы очень рады!</div>
      </section>
      <section class="info">
        <section class="info__close__wrapper">
          <close-icon class="info__close" @click.native="close" />
        </section>
        <label>Ждём вас</label>
        <div class="info__date">{{ date }}</div>
        <div class="info__label">По адресу:</div>
        <div class="info__address">
          <span v-if="office">{{ office }}</span>
          <span v-else>
            ул. Звенигородское шоссе д.18/20<br />1 корпус, 5 этаж
          </span>
        </div>
        <div class="info__other">
          В почте вы найдете подробную инструкцию, как добраться и список
          необходимых документов
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import CloseIcon from "@/assets/icons-new/close.vue";
import "./modal.scss";

export default {
  name: "modal-accept-offer",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  components: { CloseIcon },
  computed: {
    modalGif() {
      if (this.$store.getters.offer?.fields?.length) {
        const fieldModalGifList = this.$store.getters.offer.fields.filter(
          (field) => field.alias === "modal_gif"
        );

        if (fieldModalGifList?.length) {
          const fieldModalGif = fieldModalGifList[0];
          if (fieldModalGif?.value?.value?.link) {
            return fieldModalGif.value.value.link;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    office() {
      if (this.$store.getters.offer?.fields?.length) {
        const fieldOfficeList = this.$store.getters.offer.fields.filter(
          (field) => field.alias === "office"
        );
        if (fieldOfficeList?.length) {
          const field = fieldOfficeList[0];
          const officeId = field.value.value;
          const officeList = field.directory.values.filter(
            (value) => value.id === officeId
          );
          if (officeList?.length) {
            return officeList[0].caption;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  @media (max-width: 500px) {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  @media (orientation: landscape) {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  label {
    margin-top: 20px;
    display: block;
    font-family: "rotondac", sans-serif, Arial;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media (max-width: 500px) {
      font-size: 40px;
    }
  }
  &__close {
    width: 20px;
    height: 20px;
    color: #006f44;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.3s ease;
    &:hover {
      padding: 1.5px;
    }
    &:active {
      padding: 2.2px;
    }
    &__wrapper {
      display: flex;
      justify-content: flex-end;
      height: 20px;
    }
  }
  &__date {
    position: relative;
    font-family: "rotondac";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 316px;
    width: 100%;

    padding-top: 5px;
    background: linear-gradient(
      348.26deg,
      rgba(255, 255, 255, 0) -0.59%,
      rgba(255, 255, 255, 0.1) 102.18%
    );
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    @media (max-width: 500px) {
      font-size: 25px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      background: linear-gradient(
          16deg,
          rgba(255, 255, 255, 0.21),
          rgba(255, 255, 255, 0)
        )
        border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }
  &__label {
    margin-top: 80px;
    font-family: "rotondac";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media (max-width: 500px) {
      font-size: 15px;
    }
  }
  &__address {
    font-family: "rotondac";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media (max-width: 500px) {
      font-size: 12.5px;
    }
  }
  &__other {
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    font-family: "rotondac";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }
  &__image {
    padding: 46px;
    max-width: 430px;
    width: 100%;
    box-sizing: border-box;
    background: linear-gradient(
      348.26deg,
      rgba(255, 255, 255, 0.04) -0.59%,
      rgba(255, 255, 255, 0.024) 102.18%
    );
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    @media (max-width: 860px) {
      display: none;
    }
    &-label {
      font-family: "rotondac";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 125%;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      @media (max-width: 500px) {
        font-size: 25px;
      }
    }
    img {
      max-width: 308px;
      max-height: 320px;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
  }
}
</style>
