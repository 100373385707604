var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"second-section"},[_c('div',{staticClass:"label"},[_vm._v("Помимо заработной платы компания предоставляет:")]),_c('div',{staticClass:"working-conditions"},[_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('briefcase-icon')],1),_vm._m(0)]),_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('world-icon')],1),_vm._m(1)]),_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('phone-icon')],1),_vm._m(2)]),_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('education-icon')],1),_vm._m(3)]),_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('tooth-icon')],1),_vm._m(4)]),_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('discount-icon')],1),_vm._m(5)]),_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('like-icon')],1),_vm._m(6)]),(_vm.isCar)?_c('div',{staticClass:"working-condition"},[_c('div',{staticClass:"working-condition__icon"},[_c('car-icon')],1),_vm._m(7)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v(" Полное соблюдение трудового законодательства ")]),_c('div',{staticClass:"working-condition__content"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v("Оплачиваемый отпуск")]),_c('div',{staticClass:"working-condition__content"},[_vm._v(" 28 календарных дней каждый год ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v(" Корпоративная мобильная связь: ")]),_c('div',{staticClass:"working-condition__content"},[_vm._v(" Возможность подключения к корпоративному тарифу, оплата мобильной связи ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v(" Ресурсы для обучения и развития: ")]),_c('div',{staticClass:"working-condition__content"},[_vm._v(" Корпоративная библиотека образовательных курсов, карьерный портал и дополнительные тренинги ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v("ДМС")]),_c('div',{staticClass:"working-condition__content"},[_vm._v(" С возможностью расширения, включением стоматологии и ведения беременности ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v(" Корпоративная система скидок и привилегий: ")]),_c('div',{staticClass:"working-condition__content"},[_vm._v(" Выгодные предложения для сотрудников и их семей от провайдеров различных услуг ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v("Гибкий социальный пакет:")]),_c('div',{staticClass:"working-condition__content"},[_vm._v(" Кафетерий льгот – специально выделенный бюджет, на который вы можете выбрать подходящие из предложенных программ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"working-condition__label"},[_vm._v("Корпоративный автомобиль:")]),_c('div',{staticClass:"working-condition__content"},[_vm._v(" Предоставление корпоративного транспорта и компенсация ГСМ ")])])
}]

export { render, staticRenderFns }