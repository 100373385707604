<template>
  <section class="sixth-section">
    <div class="wellcome-box">
      <div class="wellcome">
        <div v-if="candidateResponse === null" class="wellcome__label">
          Ждем Вас!
        </div>
        <div v-else-if="candidateResponse === true" class="wellcome__label">
          Вы приняли оффер
        </div>
        <div v-else-if="candidateResponse === false" class="wellcome__label">
          Вы отклонили оффер
        </div>
        <div v-if="candidateResponse === null" class="wellcome__description">
          Команда Perfetti Van Melle
        </div>
      </div>
      <div
        class="avatar-box"
        v-if="responsible?.phone && candidateResponse === null"
        @click="toWhatsAppChat(responsible.phone)"
      >
        <img
          v-if="responsible?.avatar"
          class="avatar__image"
          :src="responsible.avatar"
          alt="avatar"
        />
        <aside v-else class="avatar__image">
          {{ responsible.name[0] }}
        </aside>
        <div class="avatar__info">
          <div class="avatar__icon">
            <chevrone-icon />
          </div>
          <div class="avatar__label">
            {{ responsible.name
            }}{{
              responsible.position
                ? ", " + cutText(responsible.position, 25)
                : ""
            }}
          </div>
          <div class="avatar__description">
            <div>Остаемся на связи</div>
            <div>по любым вопросам</div>
          </div>
        </div>
      </div>
    </div>
    <footer v-if="candidateResponse === null">
      <section v-if="activeUntil" class="footer__text">
        <div>Вы можете принять оффер</div>
        <div>
          до <span>{{ activeUntil }}</span>
        </div>
      </section>
      <section class="button__box">
        <button class="button__accepted" @click="accepted">
          Принять предложение
        </button>
        <button class="button__reject" @click="reject">
          Отклонить предложение
        </button>
      </section>
    </footer>
    <div class="space-60"></div>
  </section>
</template>

<script>
import ChevroneIcon from "@/assets/icons-new/chevrone.vue";

export default {
  name: "SixthSection",
  components: {
    ChevroneIcon,
  },
  data() {
    return {
      offerId: null,
      token: null,
      response: null,
    };
  },
  created() {
    window.location.search
      .replace("?", "")
      .split("&")
      .forEach((value) => {
        const temp = value.split("=");
        if (temp[0] === "id") {
          this.offerId = temp[1];
        }
        if (temp[0] === "token") {
          this.token = temp[1];
        }
      });
  },
  methods: {
    cutText(text, maxChar) {
      if (text?.length < maxChar) {
        return text;
      } else {
        return text.slice(0, maxChar - 3) + "...";
      }
    },
    toWhatsAppChat(phone) {
      if (phone) {
        const newPhone = phone.replace(/[^0-9]/g, "");
        const url = "https://wa.me/" + newPhone;
        window.open(url, "_blank").focus();
      }
    },
    accepted() {
      this.$store
        .dispatch("responseOffer", {
          offerId: this.offerId,
          token: this.token,
          response: 1,
        })
        .then(() => {
          this.response = 1;
          this.$store.commit("showModalAccept", true);
        })
        .catch(() => {
          alert("Не удалось принять оффер!");
        });
    },
    reject() {
      this.$store
        .dispatch("responseOffer", {
          offerId: this.offerId,
          token: this.token,
          response: 0,
        })
        .then(() => {
          this.response = 0;
          if (this.isComment) {
            this.$store.commit("showModalReject", true);
          } else {
            alert("Оффер успешно отклонён!");
          }
        })
        .catch(() => {
          alert("Не удалось отклонить оффер!");
        });
    },
  },
  computed: {
    candidateResponse() {
      if (this.response !== null) {
        return this.response ? true : false;
      } else if (this.$store.getters.offer?.candidate_response) {
        if (this.$store.getters.offer?.candidate_response.response === null) {
          return null;
        } else {
          return this.$store.getters.offer.candidate_response.response
            ? true
            : false;
        }
      } else {
        return null;
      }
    },
    isComment() {
      if (this.$store.getters.offer?.fields?.length) {
        return this.$store.getters.offer.fields.filter(
          (field) => field.alias === "candidates_comment"
        ).length > 0
          ? true
          : false;
      } else {
        return false;
      }
    },
    responsible() {
      if (this.$store.getters.offer?.responsible_user) {
        return this.$store.getters.offer.responsible_user;
      } else {
        return null;
      }
    },
    activeUntil() {
      if (this.$store.getters.offer?.active_until) {
        return this.$store.getters.offer.active_until;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
%text {
  font-family: "rotondac", sans-serif, Arial;
}
.sixth-section {
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 0 $indent-140;
  margin-top: $indent-140;
  @media (max-width: 1290px) {
    padding: 0 $indent-20;
  }
  @media (max-width: 600px) {
    margin-top: 180px;
  }
}
.wellcome-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: $indent-60;
  @media (max-width: 1290px) {
    flex-direction: column;
  }
}
.wellcome {
  width: 600px;
  @media (max-width: 1290px) {
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
  }
  &__label {
    font-size: $font-size-40;
    font-weight: 700;
    color: $color-primary;
    @extend %text;
    @media (max-width: 1290px) {
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.05em;
    }
    @media (max-width: 600px) {
      font-size: 21px;
    }
  }
  &__description {
    font-size: $font-size-40;
    font-weight: 700;
    color: $color-default;
    @extend %text;
    @media (max-width: 1290px) {
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.05em;
    }
    @media (max-width: 600px) {
      font-size: 21px;
    }
  }
}
.avatar-box {
  display: flex;
  width: 454px;
  height: 140px;
  padding: 20px;
  background: linear-gradient(
    134.78deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.068) 52.59%,
    rgba(255, 255, 255, 0) 113.45%
  );
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 26px;
  cursor: pointer;
  @media (max-width: 1290px) {
    margin: 0 auto;
  }
  @media (max-width: 580px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 510px) {
    height: auto;
  }
  @media (max-width: 350px) {
    justify-content: flex-start;
  }
  .avatar {
    &__info {
      position: relative;
    }
    &__icon {
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__image {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      line-height: 40px;

      min-width: 100px;
      min-height: 100px;
      max-width: 100px;
      max-height: 100px;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 2px solid #ffffff24;
      margin-right: $indent-20;
      @media (max-width: 510px) {
        line-height: 25px;
        font-size: 25px;
        width: 65px;
        height: 65px;
        max-width: 65px;
        max-height: 65px;
        min-width: 65px;
        min-height: 65px;
      }
    }
    &__label {
      color: $color-default;
      font-weight: 400;
      font-size: $font-size-16;
      line-height: 150%;
      margin-bottom: $indent-10;
      @extend %text;
      @media (max-width: 510px) {
        font-size: 9px;
      }
    }
    &__description {
      color: $color-default;
      font-weight: 700;
      font-size: $font-size-24;
      line-height: 130%;
      @extend %text;
      @media (max-width: 510px) {
        font-size: 14px;
      }
    }
  }
}
.space-60 {
  width: 100%;
  height: $indent-60;
}
footer {
  display: flex;
  justify-content: space-between;
  padding: $indent-60;
  background: linear-gradient(
    348.26deg,
    rgba(255, 255, 255, 0.04) -0.59%,
    rgba(255, 255, 255, 0) 102.18%
  );
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 26px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 800px) {
    padding: $indent-30;
  }
  .footer__text {
    color: $color-default;
    font-weight: 400;
    font-size: $font-size-16;
    line-height: 150%;
    @extend %text;
    @media (max-width: 1000px) {
      margin-bottom: 35px;
      div {
        display: inline;
        margin-left: 5px;
      }
    }
    @media (max-width: 400px) {
      div {
        display: block;
        margin: 0;
        text-align: center;
      }
    }
    span {
      color: $color-primary;
    }
  }
  .button__box {
    margin-left: -$indent-30;
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
    }
  }
  button {
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: $color-default;
    padding: $indent-10 $indent-20;
    margin-left: $indent-30;
    cursor: pointer;
    @extend %text;
    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 150%;
    }
    @media (max-width: 380px) {
      font-size: 15px;
    }
  }
  .button__accepted {
    background: $color-green;
    @media (max-width: 800px) {
      margin-bottom: 20px;
    }
  }
  .button__reject {
    background: linear-gradient(
      348.26deg,
      rgba(255, 255, 255, 0) -0.59%,
      rgba(255, 255, 255, 0.1) 102.18%
    );
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09);
  }
}
</style>
