<template>
  <section>
    <section class="first-section">
      <div class="logo">
        <img src="/theme/logo.png" alt="" />
      </div>
      <div class="name">Добрый день, {{ name ? name : "кандидат" }}!</div>
      <div class="label">
        С радостью приглашаем Вас присоединиться к нашей команде
        <span v-if="vacancy?.name"> на позиции {{ vacancy.name }}</span
        >!
      </div>
      <div class="offer">
        <div class="offer-block">
          <img
            class="offer-block__image"
            src="@/assets/images/coins.png"
            alt="Заработная плата"
          />
          <div>
            <div class="offer-block__label">
              Заработная плата, включая налог (и «северные» выплаты, если
              применимо)
            </div>
            <div v-if="salary" class="offer-block__description">
              <span>{{ toDivide(salary) }}</span>
              <span class="ruble">i</span> в месяц
            </div>
            <div v-else class="offer-block__description">Не указана</div>
          </div>
        </div>
        <div class="offer-block" v-if="premiumList?.length">
          <img
            class="offer-block__image"
            src="@/assets/images/chart.png"
            alt="Заработная плата"
          />
          <div>
            <div class="offer-block__label">
              Премирование согласно трудовому договору и правилам компании:
            </div>
            <div
              v-if="premiumList?.length > 1"
              class="offer-block__description"
            >
              <div v-for="premium in premiumList" :key="premium.alias">
                <strong>{{ premium.value.value }}%</strong>
                {{ convertAliasPremium(premium.alias) }}
              </div>
            </div>
            <div v-else class="offer-block__description">
              <div v-for="premium in premiumList" :key="premium.alias">
                <span>{{ premium.value.value }}%</span>
                {{ convertAliasPremium(premium.alias) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="offer__description">
      <div v-if="subdivision" class="offer-info">
        <div class="offer-info__icon"><puzzles-icon /></div>
        <div class="offer-info__label">Подразделение</div>
        <div class="offer-info__description">
          {{ subdivision }}
        </div>
      </div>
      <div v-if="supervisor" class="offer-info">
        <div class="offer-info__icon"><supervisor-icon /></div>
        <div class="offer-info__label">Руководитель</div>
        <div class="offer-info__description">
          <div>{{ supervisor }}</div>
        </div>
      </div>
      <div class="offer-info">
        <div class="offer-info__icon"><tie-icon /></div>
        <div class="offer-info__label">График работы</div>
        <div class="offer-info__description">
          <div>
            Пн-Пт 9:00-18:00<br />(может быть пересмотрен по согласованию)
            <br />40-часовая рабочая неделя
          </div>
        </div>
      </div>
      <div class="offer-info">
        <div class="offer-info__icon"><moneybox-icon /></div>
        <div class="offer-info__label">Выплата заработной платы</div>
        <div class="offer-info__description">2 раза в месяц</div>
      </div>
    </div>
  </section>
</template>

<script>
import PuzzlesIcon from "@/assets/icons-new/puzzles.vue";
import SupervisorIcon from "@/assets/icons-new/supervisor.vue";
import TieIcon from "@/assets/icons-new/tie.vue";
import MoneyboxIcon from "@/assets/icons-new/moneybox.vue";

export default {
  name: "FirstSection",
  components: {
    PuzzlesIcon,
    SupervisorIcon,
    TieIcon,
    MoneyboxIcon,
  },
  computed: {
    sex() {
      if (this.$store.getters.offer?.candidate?.sex) {
        return this.$store.getters.offer.candidate.sex;
      } else {
        return "m";
      }
    },
    vacancy() {
      if (this.$store.getters.offer?.vacancy) {
        return this.$store.getters.offer.vacancy;
      } else {
        return null;
      }
    },
    premiumList() {
      let premiumList = [];
      if (this.$store.getters.offer?.fields?.length) {
        this.$store.getters.offer.fields.forEach((field) => {
          if (
            [
              "amount_monthly_premium",
              "amount_quarterly_premium",
              "amount_annual_premium",
            ].includes(field.alias)
          ) {
            if (field?.value?.value > 0) {
              premiumList.push(field);
            }
          }
        });
      }
      return premiumList;
    },
    salary() {
      let salary = null;
      if (this.$store.getters.offer?.fields?.length) {
        this.$store.getters.offer.fields.forEach((field) => {
          if (field.alias === "salary") {
            if (field?.value?.value) {
              salary = field.value.value;
            }
          }
        });
      }
      return salary;
    },
    subdivision() {
      let subdivision = null;
      if (this.$store.getters.offer?.fields?.length) {
        this.$store.getters.offer.fields.forEach((field) => {
          if (field.alias === "subdivision") {
            if (field?.value?.value) {
              subdivision = field.value.value;
            }
          }
        });
      }
      return subdivision;
    },
    supervisor() {
      let supervisor = null;
      if (this.$store.getters.offer?.fields?.length) {
        this.$store.getters.offer.fields.forEach((field) => {
          if (field.alias === "supervisor" || field.alias === "Supervisor") {
            if (field?.value?.value) {
              supervisor = field.value.value;
            }
          }
        });
      }
      return supervisor;
    },
    schedule() {
      let schedule = null;
      if (this.$store.getters.offer?.fields?.length) {
        this.$store.getters.offer.fields.forEach((field) => {
          if (field.alias === "schedule") {
            if (field?.value?.value) {
              schedule = field.value.value;
            }
          }
        });
      }
      return schedule;
    },
    name() {
      if (this.$store.getters.offer?.candidate?.name) {
        return this.$store.getters.offer.candidate.name;
      } else {
        return null;
      }
    },
  },
  methods: {
    toDivide(value) {
      let int = String(Math.trunc(value));
      if (int.length <= 3) return int;
      let space = 0;
      let number = "";

      for (var i = int.length - 1; i >= 0; i--) {
        if (space == 3) {
          number = " " + number;
          space = 0;
        }
        number = int.charAt(i) + number;
        space++;
      }

      return number;
    },
    getCurrentYear(min = true) {
      if (min) {
        return new Date().getYear() - 100;
      } else {
        return new Date().getFullYear();
      }
    },
    convertAliasPremium(alias) {
      switch (alias) {
        case "amount_monthly_premium":
          return "дохода за месяц";
        case "amount_quarterly_premium":
          return "от квартального дохода (3х окладов)";
        case "amount_annual_premium":
          return `от годового дохода (оклад на 31.12.${this.getCurrentYear()} * количество отработанных за год месяцев)`;
        default:
          return alias;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
%text {
  font-family: "rotondac", sans-serif, Arial;
}
.ruble {
  font-family: rouble, sans-serif !important;
  font-size: $font-size-24 !important;
  line-height: 110% !important;
  font-weight: 400 !important;
  @media (max-width: 900px) {
    font-size: 12px !important;
  }
}

.first-section {
  position: relative;
  z-index: 1;
  padding-top: $indent-60;
  padding-bottom: $indent-80;
  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
  background: linear-gradient(
    348.26deg,
    rgba(255, 255, 255, 0.1) -0.59%,
    rgba(255, 255, 255, 0) 102.18%
  );
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(67.5px);
  @media (max-width: 900px) {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}
.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: $indent-80;
  @media (max-width: 900px) {
    margin-bottom: $indent-60;
  }
  img {
    width: auto;
    height: 75px;
    @media (max-width: 1400px) {
      height: 50px;
    }
  }
}
.name {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: $color-default;
  font-size: $font-size-48;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.05em;
  margin-bottom: $indent-20;
  @extend %text;
  @media (max-width: 1400px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 125%;
    letter-spacing: 0.05em;
    padding: 0 $indent-20;
  }
}
.label {
  width: 100%;
  text-align: center;
  color: $color-default;
  font-size: $font-size-28;
  font-weight: 400;
  max-width: 594px;
  line-height: 31px;
  margin: 0 auto;
  margin-bottom: $indent-100;
  @extend %text;
  @media (max-width: 1400px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    max-width: 440px;
    padding: 0 $indent-20;
    margin-bottom: $indent-60;
  }
}
.offer {
  display: flex;
  justify-content: center;
  margin-left: -$indent-50;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 $indent-20;
    margin: 0;
  }
  &__description {
    display: flex;
    justify-content: center;
    margin-left: -$indent-40;
    position: relative;
    flex-wrap: wrap;
    top: -70px;
    left: 0;
    z-index: 20;
    @media (max-width: 1260px) {
      max-width: 680px;
      margin: 0 auto;
    }
    @media (max-width: 900px) {
      width: 100%;
    }
    @media (max-width: 500px) {
      flex-wrap: wrap;
    }
  }
}
.offer-block {
  position: relative;
  max-width: 400px;
  width: 100%;
  background: linear-gradient(
    348.26deg,
    rgba(255, 255, 255, 0.04) -0.59%,
    rgba(255, 255, 255, 0) 102.18%
  );
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 26px;
  padding: $indent-40;
  margin-left: $indent-50;
  @media (max-width: 900px) {
    max-width: 100%;
    margin: 0;
    padding: $indent-20 $indent-10;
    display: flex;
  }
  &:last-child {
    @media (max-width: 900px) {
      margin-top: $indent-20;
    }
  }
  &__image {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    height: 100px;
    width: auto;
    @media (max-width: 900px) {
      position: static;
      width: 100px;
      height: 100px;
      margin-right: $indent-10;
      transform: none;
    }
  }
  &__label {
    display: inline-block;
    color: $color-default;
    @extend %text;
    text-align: left;
    font-size: $font-size-16;
    font-weight: 400;
    line-height: 18px;
    margin-top: $indent-20;
    @media (max-width: 900px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
  }
  &__description {
    display: inline-block;
    color: $color-primary;
    @extend %text;
    text-align: left;
    font-size: $font-size-24;
    line-height: 110%;
    font-weight: 400;
    margin-top: $indent-20;
    @media (max-width: 900px) {
      font-weight: 700;
      font-size: 12px;
    }
    span {
      @extend %text;
      font-size: $font-size-36;
      font-weight: 700;
      @media (max-width: 900px) {
        font-weight: 700;
        font-size: 21px;
      }
    }
  }
}
.offer-info {
  max-width: 240px;
  min-width: 135px;
  width: 100%;
  margin-left: $indent-40;
  margin-top: $indent-40;
  @media (max-width: 900px) {
    margin-left: $indent-20;
  }
  &__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $color-default;
    width: 60px;
    height: 60px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.31) 0%,
      rgba(21, 64, 121, 1) 100%
    );
    @media (max-width: 900px) {
      margin: 0 auto;
    }
    &:before {
      content: "";
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.2) 100%
      );
      border-radius: 50%;
      z-index: -1;
    }
  }
  &__label {
    display: block;
    color: $color-primary;
    @extend %text;
    text-align: left;
    font-size: $font-size-16;
    font-weight: 700;
    line-height: 150%;
    margin-top: $indent-20;
    @media (max-width: 900px) {
      text-align: center;
      font-size: 16px;
      line-height: 150%;
      width: 100%;
    }
  }
  &__description {
    display: inline-block;
    color: $color-default;
    @extend %text;
    text-align: left;
    font-size: $font-size-16;
    font-weight: 400;
    line-height: 150%;
    margin-top: $indent-10;
    @media (max-width: 900px) {
      text-align: center;
      font-size: 16px;
      line-height: 150%;
      width: 100%;
    }
  }
}
</style>
