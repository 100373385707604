<template>
  <main v-if="!loading && !isHideData">
    <section class="background">
      <section class="content">
        <first-section />
        <second-section />
        <third-section />
        <fourth-section />
        <fifth-section />
        <sixth-section />
      </section>
    </section>
    <modal-accept-offer
      v-if="showModalAccept"
      :date="offer?.date_of_employment ? offer.date_of_employment : ''"
      @close="closeModalAccept"
    />
    <modal-reject-offer v-if="showModalReject" @close="closeModalReject" />
  </main>
  <main v-else-if="loading && !isHideData">
    <section class="loading-wrapper">
      <loading-simple />
    </section>
  </main>
  <main v-else>
    <section class="offer__not-found">
      <div>Оффер не найден.</div>
      <div>Используете индивидуальную пригласительную ссылку.</div>
    </section>
  </main>
</template>

<script>
import FirstSection from "@/components/sections/First.vue";
import SecondSection from "@/components/sections/Second.vue";
import ThirdSection from "@/components/sections/Third.vue";
import FourthSection from "@/components/sections/Fourth.vue";
import FifthSection from "@/components/sections/Fifth.vue";
import SixthSection from "@/components/sections/Sixth.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import { ModalAcceptOffer } from "@/components";
import { ModalRejectOffer } from "@/components";

export default {
  name: "LandingPage",
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection,
    LoadingSimple,
    ModalAcceptOffer,
    ModalRejectOffer,
  },
  data() {
    return {
      isHideData: false,
      loading: true,
    };
  },
  computed: {
    offer() {
      if (this.$store.getters.offer) {
        return this.$store.getters.offer;
      } else {
        return null;
      }
    },
    showModalReject() {
      if (this.$store.getters.showModalReject) {
        return this.$store.getters.showModalReject;
      } else {
        return false;
      }
    },
    showModalAccept() {
      if (this.$store.getters.showModalAccept) {
        return this.$store.getters.showModalAccept;
      } else {
        return false;
      }
    },
  },
  created() {
    const queryParams = this.getQueryParams();
    const viewer = queryParams.viewer;
    const id = queryParams.id;
    const token = queryParams.token;

    this.loading = true;

    if (id && token) {
      this.$store
        .dispatch("offer", {
          id,
          token,
          isAdministration: viewer === "administration",
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.isHideData = true;
          this.loading = false;
        });
    } else {
      this.isHideData = true;
      this.loading = false;
    }
  },
  methods: {
    getQueryParams() {
      return location.search
        .slice(1)
        .split("&")
        .map((p) => p.split("="))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
    },
    closeModalAccept() {
      this.$store.commit("showModalAccept", false);
    },
    closeModalReject() {
      this.$store.commit("showModalReject", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  /* position: relative; */
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.background {
  background-image: url("@/assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #1a569a;
}
.gradient {
  &--1 {
    position: absolute;
    width: 1208px;
    height: 825px;
    left: 668px;
    top: 4042px;
    background: #ffa300;
    filter: blur(100px);
  }
  &--2 {
    position: absolute;
    width: 872px;
    height: 872px;
    left: 307px;
    top: 4px;
    background: #0077c8;
    filter: blur(100px);
  }
  &--3 {
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: 179px;
    top: -588px;
    background: #0033a0;
    filter: blur(100px);
  }
  &--4 {
    position: absolute;
    width: 825px;
    height: 825px;
    left: 1252px;
    top: 3412px;
    background: #ed028a;
    filter: blur(100px);
  }
  &--5 {
    position: absolute;
    width: 302px;
    height: 302px;
    left: 1225px;
    top: 555px;
    background: #0033a0;
    filter: blur(232.5px);
  }
  &--6 {
    position: absolute;
    width: 654px;
    height: 654px;
    left: -163px;
    top: 461px;
    background: #0033a0;
    filter: blur(100px);
  }
  &--7 {
    position: absolute;
    width: 925px;
    height: 925px;
    left: -423px;
    top: 2040px;
    background: #6cc24a;
    filter: blur(100px);
  }
  &--8 {
    position: absolute;
    width: 555px;
    height: 555px;
    left: 391px;
    top: 2175px;
    background: #009639;
    filter: blur(100px);
  }
}
.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aside {
    color: #fff;
    height: 64px;
  }
}
.offer__not-found {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  width: 100%;
  color: #fff;
  font-family: "rotondac", sans-serif, Arial;
  font-size: $font-size-48;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.05em;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 900px) {
    font-size: 38px;
  }
  @media (max-width: 600px) {
    font-size: 28px;
  }
  div {
    &:last-child {
      margin-top: 30px;
      font-weight: 400;
      font-size: 28px;
      line-height: 31px;
      @media (max-width: 900px) {
        font-size: 18px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
</style>
