<template>
  <section class="second-section">
    <div class="label">Помимо заработной платы компания предоставляет:</div>
    <div class="working-conditions">
      <div class="working-condition">
        <div class="working-condition__icon">
          <briefcase-icon />
        </div>
        <div>
          <div class="working-condition__label">
            Полное соблюдение трудового законодательства
          </div>
          <div class="working-condition__content"></div>
        </div>
      </div>
      <div class="working-condition">
        <div class="working-condition__icon">
          <world-icon />
        </div>
        <div>
          <div class="working-condition__label">Оплачиваемый отпуск</div>
          <div class="working-condition__content">
            28 календарных дней каждый год
          </div>
        </div>
      </div>
      <div class="working-condition">
        <div class="working-condition__icon">
          <phone-icon />
        </div>
        <div>
          <div class="working-condition__label">
            Корпоративная мобильная связь:
          </div>
          <div class="working-condition__content">
            Возможность подключения к корпоративному тарифу, оплата мобильной
            связи
          </div>
        </div>
      </div>
      <div class="working-condition">
        <div class="working-condition__icon"><education-icon /></div>
        <div>
          <div class="working-condition__label">
            Ресурсы для обучения и развития:
          </div>
          <div class="working-condition__content">
            Корпоративная библиотека образовательных курсов, карьерный портал и
            дополнительные тренинги
          </div>
        </div>
      </div>
      <div class="working-condition">
        <div class="working-condition__icon">
          <tooth-icon />
        </div>
        <div>
          <div class="working-condition__label">ДМС</div>
          <div class="working-condition__content">
            С возможностью расширения, включением стоматологии и ведения
            беременности
          </div>
        </div>
      </div>
      <div class="working-condition">
        <div class="working-condition__icon"><discount-icon /></div>
        <div>
          <div class="working-condition__label">
            Корпоративная система скидок и привилегий:
          </div>
          <div class="working-condition__content">
            Выгодные предложения для сотрудников и их семей от провайдеров
            различных услуг
          </div>
        </div>
      </div>
      <div class="working-condition">
        <div class="working-condition__icon"><like-icon /></div>
        <div>
          <div class="working-condition__label">Гибкий социальный пакет:</div>
          <div class="working-condition__content">
            Кафетерий льгот – специально выделенный бюджет, на который вы можете
            выбрать подходящие из предложенных программ
          </div>
        </div>
      </div>
      <div v-if="isCar" class="working-condition">
        <div class="working-condition__icon"><car-icon /></div>
        <div>
          <div class="working-condition__label">Корпоративный автомобиль:</div>
          <div class="working-condition__content">
            Предоставление корпоративного транспорта и компенсация ГСМ
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BriefcaseIcon from "@/assets/icons-new/briefcase.vue";
import CarIcon from "@/assets/icons-new/car.vue";
import WorldIcon from "@/assets/icons-new/world.vue";
import PhoneIcon from "@/assets/icons-new/phone.vue";
import EducationIcon from "@/assets/icons-new/education.vue";
import ToothIcon from "@/assets/icons-new/tooth.vue";
import DiscountIcon from "@/assets/icons-new/discount.vue";
import LikeIcon from "@/assets/icons-new/like.vue";

export default {
  name: "SecondSection",
  components: {
    CarIcon,
    BriefcaseIcon,
    WorldIcon,
    PhoneIcon,
    EducationIcon,
    ToothIcon,
    DiscountIcon,
    LikeIcon,
  },
  computed: {
    isCar() {
      if (!this.$store.getters.offer?.fields?.length) return false;

      const cars = this.$store.getters.offer.fields.filter(
        (field) => field.alias === "corporate_car_and_fuel_payment"
      );

      if (!cars?.length) return false;

      const car = cars[0];

      if (!car.value) return false;

      return !!car.value.value;
    },
  },
};
</script>

<style lang="scss" scoped>
%text {
  font-family: "rotondac", sans-serif, Arial;
}
.second-section {
  position: relative;
  width: 100%;
  z-index: 1;
}
.label {
  max-width: 960px;
  font-weight: 700;
  font-size: $font-size-40;
  line-height: 125%;
  letter-spacing: 0.05em;
  margin-top: $indent-120;
  margin-right: auto;
  margin-left: auto;
  color: $color-default;
  text-align: center;
  @extend %text;
  @media (max-width: 900px) {
    margin-top: $indent-60;
    padding: 0 20px;
  }
  @media (max-width: 600px) {
    font-size: 30px;
  }
}
.working-conditions {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$indent-40;
  justify-content: center;
  @media (max-width: 600px) {
    margin-left: 0;
    padding: 0 20px;
  }
}

.working-condition {
  position: relative;
  margin-left: $indent-40;
  margin-top: $indent-100;
  display: flex;
  width: 260px;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.04) -4.07%,
    rgba(255, 255, 255, 0) 62.96%
  );
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 26px;
  padding: $indent-60 $indent-30 $indent-30 $indent-30;
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    padding: 0;
    flex-direction: row;
    background: none;
    box-shadow: none;
    margin-top: 30px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    color: $color-blue;
    width: 60px;
    height: 60px;
    @media (max-width: 600px) {
      position: relative;
      transform: none;
      max-width: 60px;
      min-width: 60px;
      max-height: 60px;
      min-height: 60px;
      top: 0;
      left: 0;
      margin-right: 10px;
    }
    svg {
      position: relative;
      z-index: 2;
    }
    &:before {
      content: "";
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.5) 100%
      );
      border-radius: 50%;
      z-index: -1;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: black;
      background: linear-gradient(
        to bottom,
        rgba(82, 91, 110, 1) 0%,
        rgba(43, 52, 71, 1) 100%
      );
      border-radius: 50%;
      z-index: 1;
    }
  }
  &__label {
    font-weight: 700;
    font-size: $font-size-16;
    line-height: 125%;
    color: $color-primary;
    @extend %text;
  }
  &__content {
    margin-top: $indent-20;
    color: $color-default;
  }
}
</style>
