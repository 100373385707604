<template>
  <section class="modal-simple">
    <section class="modal-simple__bg" @click="close"></section>
    <section class="modal-simple__content">
      <img
        class="modal-simple__background"
        src="@/assets/images/modal-reject-bg.jpg"
      />
      <section class="modal-simple__content-bg">
        <section class="modal-simple__content-title">
          <img class="heart" src="@/assets/images/heart.png" alt="" />
          <div class="title">Нам очень жаль!</div>
        </section>
        <textarea
          v-model="comment"
          class="comment"
          :placeholder="
            responsible?.phone
              ? 'Пожалуйста, оставьте свои комментарии здесь, или, при необходимости, свяжитесь со специалистом по персоналу.'
              : 'Пожалуйста, оставьте свои комментарии здесь.'
          "
        ></textarea>
      </section>
      <section class="modal-simple__content-actions">
        <section class="avatar-box" v-if="responsible?.phone">
          <img
            v-if="responsible?.avatar"
            class="avatar"
            :src="responsible.avatar"
            alt="avatar"
          />
          <aside v-else class="avatar" style="background: #ffffff24">
            {{ responsible?.name?.length ? responsible.name[0] : "?" }}
          </aside>
          <section class="avatar-info">
            <section class="avatar__name">
              {{ responsible?.name ? responsible.name : "???" }}
              {{
                responsible?.position?.length ? ", " + responsible.position : ""
              }}
            </section>
            <button
              class="avatar__button"
              @click="toWhatsAppChat(responsible.phone)"
            >
              Связаться
            </button>
          </section>
        </section>
        <button @click="sendComment">Отправить</button>
      </section>
    </section>
  </section>
</template>

<script>
import CloseIcon from "@/assets/icons-new/close.vue";
import "./modal.scss";

export default {
  name: "modal-reject-offer",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      comment: "",
    };
  },
  components: { CloseIcon },
  computed: {
    fieldComment() {
      if (this.$store.getters.offer?.fields?.length) {
        const fieldComments = this.$store.getters.offer.fields.filter(
          (field) => field.alias === "candidates_comment"
        );
        return fieldComments[0];
      } else {
        return null;
      }
    },
    candidate() {
      if (this.$store.getters.offer?.candidate) {
        return this.$store.getters.offer.candidate;
      } else {
        return null;
      }
    },
    responsible() {
      if (this.$store.getters.offer?.responsible_user) {
        return this.$store.getters.offer.responsible_user;
      } else {
        return null;
      }
    },
  },
  methods: {
    sendComment() {
      if (this.fieldComment) {
        let token = null;
        let offerId = null;
        window.location.search
          .replace("?", "")
          .split("&")
          .forEach((value) => {
            const temp = value.split("=");
            if (temp[0] === "id") {
              offerId = temp[1];
            }
            if (temp[0] === "token") {
              token = temp[1];
            }
          });
        if (token && this.comment && this.fieldComment && offerId) {
          this.$store.dispatch("sendComment", {
            fieldId: this.fieldComment.id,
            offerId,
            token,
            comment: this.comment,
          });
        }
      }
      this.$store.commit("showModalReject", false);
    },
    toWhatsAppChat(phone) {
      if (phone) {
        const newPhone = phone.replace(/[^0-9]/g, "");
        const url = "https://wa.me/" + newPhone;
        window.open(url, "_blank").focus();
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-simple {
  &__content {
    display: flex;
    flex-direction: column;
    max-height: 671px;
    @media (max-width: 640px) {
      max-height: 100%;
      border-radius: 0;
    }
    @media (max-height: 671px) and (orientation: landscape) {
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }
    &-title {
      display: flex;
      align-items: center;
      & > .heart {
        width: 213.7px;
        height: 192.041px;
        margin-right: 75px;
        @media (max-width: 890px) {
          display: none;
        }
      }
      & > .title {
        color: #fff;
        text-align: center;
        font-family: RotondaC;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.28px;
        @media (max-width: 890px) {
          height: 192px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        @media (max-width: 640px) {
          height: 100px;
          font-size: 30px;
        }
        @media (max-height: 671px) and (orientation: landscape) {
          height: 80px;
          font-size: 30px;
        }
      }
    }
    &-actions {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 640px) {
        margin-left: -20px;
        justify-content: flex-end;
      }
    }
    &-bg {
      box-sizing: border-box;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 509px;
      border-radius: 10px;
      background: linear-gradient(
        341deg,
        rgba(255, 255, 255, 0.04) 0%,
        rgba(255, 255, 255, 0.02) 100%
      );
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(39.5px);
    }
  }
}
.avatar {
  width: 84px;
  height: 84px;
  min-width: 84px;
  min-height: 84px;
  max-width: 84px;
  max-height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: 2px solid rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.1);
  margin-right: 20px;
  @media (max-width: 640px) {
    display: none;
  }
  &-info {
    width: 100%;
    @media (max-width: 640px) {
      width: max-content;
    }
  }
  &__name {
    color: #fff;
    font-family: RotondaC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 10px;
    @media (max-width: 640px) {
      display: none;
    }
  }
  &__button {
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #0077c8;
    @media (max-width: 640px) {
      width: max-content;
    }
  }
  &-box {
    padding: 8px 10px 8px 8px;
    display: flex;
    width: 387px;
    height: 102px;
    border-radius: 10px;
    background: linear-gradient(
      170deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.07) 46.35%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(1px);
    @media (max-width: 640px) {
      box-shadow: none;
      background: none;
      height: auto;
      width: max-content;
      padding: 0;
    }
  }
}
button {
  display: flex;
  padding: 12px 30px;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 10px;
  background: #6cc24a;
  color: #fff;
  text-align: center;
  font-family: RotondaC;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
  @media (max-width: 640px) {
    font-size: 18px;
    padding: 10px 15px;
    margin-left: 20px;
  }
}
.comment {
  border: none;
  outline: none;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
  font-family: RotondaC;
  width: calc(100% - 40px);
  min-width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  resize: none;
  margin: 0 20px;
  height: 100%;
  border-radius: 5px;
  background: #fff;
  &::placeholder {
    color: #c0c0c0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #c0c0c0;
    font-family: RotondaC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &::-ms-input-placeholder {
    color: #c0c0c0;
    font-family: RotondaC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
</style>
