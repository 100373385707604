<template>
  <section class="third-section">
    <div class="label">Почему мы любим PVM</div>
    <div class="love-box">
      <div class="love">
        <img class="love__image" src="@/assets/images/cup.png" />
        <div>
          <div class="love__label">
            <div>Работа с всемирно известными,</div>
            <div>легендарными брендами</div>
          </div>
          <div class="love__content">
            В PVM мы гордимся тем, что предлагаем нашим сотрудникам возможность
            работать с глобальными брендами, которые любят во всем мире, в
            веселой вдохновляющей среде
          </div>
        </div>
      </div>
      <div class="love">
        <img class="love__image" src="@/assets/images/rocket.png" />
        <div>
          <div class="love__label">
            <div>Динамичный</div>
            <div>и быстрорастущий бизнес</div>
          </div>
          <div class="love__content">
            В PVM каждый день — это новый вызов, поскольку мы стремимся
            удовлетворить постоянно меняющиеся предпочтения наших потребителей.
            Мы гордимся нашей динамичной рабочей средой, которая позволяет людям
            брать на себя ответственность и чувствовать дух победы
          </div>
        </div>
      </div>
      <div class="love">
        <img class="love__image" src="@/assets/images/briefcase.png" />
        <div>
          <div class="love__label">
            <div>Компания инновационного</div>
            <div>и предпринимательского подхода</div>
          </div>
          <div class="love__content">
            Мы ценим предпринимательский дух наших сотрудников. В PVM каждый
            сотрудник может свободно предлагать новые идеи и принимать
            нестандартные решения
          </div>
        </div>
      </div>
      <div class="love">
        <img class="love__image" src="@/assets/images/chat.png" />
        <div>
          <div class="love__label">
            <div>Поддерживающая</div>
            <div>и вдохновляющая среда</div>
          </div>
          <div class="love__content">
            Наша дружественная корпоративная среда, открытая и вовлекающая
            культура позволяют нашим сотрудникам добиваться успеха и
            реализовывать свой потенциал. Люди в PVM имеют возможность каждый
            день развивать свою карьеру и наш бизнес
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ThirdSection",
};
</script>

<style lang="scss" scoped>
%text {
  font-family: "rotondac", sans-serif, Arial;
}
.third-section {
  position: relative;
  width: 100%;
  z-index: 1;
}
.label {
  max-width: 960px;
  font-weight: 700;
  font-size: $font-size-40;
  line-height: 125%;
  letter-spacing: 0.05em;
  margin-top: $indent-120;
  margin-right: auto;
  margin-left: auto;
  color: $color-default;
  text-align: center;
  @extend %text;
  @media (max-width: 600px) {
    font-size: 21px;
  }
}
.love-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 900px) {
    padding: 0 20px;
  }
}
.love {
  position: relative;
  @extend %text;
  color: $color-default;
  width: 560px;
  margin-top: $indent-140;
  margin-left: $indent-40;
  padding: $indent-100 $indent-60 $indent-30 $indent-60;
  border-radius: 26px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.04) -4.07%,
    rgba(255, 255, 255, 0) 62.96%
  );
  box-shadow: 0px -26px 30px rgba(0, 0, 0, 0.05);
  @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 600px) {
    padding: 30px 30px 30px 15px;
    display: flex;
    margin-top: 30px;
  }
  &__image {
    position: absolute;
    width: auto;
    height: 152px;
    top: -76px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 600px) {
      height: 86px;
      position: static;
      transform: none;
      margin-right: 20px;
    }
    @media (max-width: 400px) {
      height: 57px;
    }
  }
  &__label {
    font-weight: 700;
    line-height: 150%;
    font-size: $font-size-16;
    text-align: center;
    @media (max-width: 600px) {
      text-align: left;
    }
    @media (max-width: 400px) {
      font-size: 10px;
    }
  }
  &__content {
    margin-top: $indent-30;
    font-weight: 400;
    line-height: 150%;
    font-size: $font-size-16;
    text-align: center;
    @media (max-width: 600px) {
      text-align: left;
    }
    @media (max-width: 400px) {
      font-size: 10px;
      margin-top: 15px;
    }
  }
}
</style>
