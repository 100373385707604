<template>
  <section class="fourth-section">
    <div class="label">Вам понадобятся следующие документы:</div>
    <div class="document-box">
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">Паспорт</div>
      </div>
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">ИНН</div>
      </div>
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">Трудовая книжка</div>
      </div>
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">Свидетельство о регистрации брака</div>
      </div>
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">Пенсионное удостоверение</div>
      </div>
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">Военный билет</div>
      </div>
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">Диплом о высшем образовании</div>
      </div>
      <div class="document">
        <div class="document__icon">
          <success-icon />
        </div>
        <div class="document__label">Свидетельство о рождении детей</div>
      </div>
      <div class="document"></div>
      <div class="document"></div>
      <div class="document"></div>
      <div class="document"></div>
      <div class="document"></div>
      <div class="document"></div>
      <div class="document"></div>
      <div class="document"></div>
    </div>
  </section>
</template>

<script>
import SuccessIcon from "@/assets/icons-new/success.vue";

export default {
  name: "FourthSection",
  components: {
    SuccessIcon,
  },
};
</script>

<style lang="scss" scoped>
%text {
  font-family: "rotondac", sans-serif, Arial;
}
.fourth-section {
  position: relative;
  width: 100%;
  z-index: 1;
}
.label {
  max-width: 960px;
  font-weight: 700;
  font-size: $font-size-40;
  line-height: 125%;
  letter-spacing: 0.05em;
  margin-top: $indent-120;
  margin-right: auto;
  margin-left: auto;
  color: $color-default;
  text-align: center;
  @extend %text;
  @media (max-width: 600px) {
    font-size: 21px;
    margin-top: $indent-60;
    padding: 0 20px;
  }
}
.document-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: $indent-100;
  margin-top: $indent-20;
  @media (max-width: 1300px) {
    margin-left: 20px;
  }
  @media (max-width: 560px) {
    margin-left: -20px;
  }
  .document {
    display: flex;
    align-items: center;
    width: 300px;
    margin-top: $indent-40;
    @media (max-width: 560px) {
      width: 140px;
      margin-left: 20px;
    }
    &:empty {
      padding: 0;
      margin: 0;
    }
    &__icon {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      width: 40px;
      height: 40px;
      background: $color-primary;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: $indent-20;
      @media (max-width: 560px) {
        min-width: 29px;
        min-height: 29px;
        max-width: 29px;
        max-height: 29px;
        width: 29px;
        height: 29px;
        svg {
          width: 18px;
          height: auto;
        }
      }
    }
    &__label {
      color: $color-default;
      font-weight: 700;
      font-size: $font-size-20;
      @extend %text;
      @media (max-width: 560px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
</style>
