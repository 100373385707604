<template>
  <section class="map-box">
    <div class="map-box__image-wrapper">
      <img class="map" :src="getImgUrl(address.img)" alt="" />
    </div>
    <section class="address__wrapper">
      <section class="map__address-box" @click="toogleAddress">
        <div class="address__icon">
          <pointer-icon />
        </div>
        <div class="address">
          {{ address.label }}
        </div>
        <div class="address__dropdown-icon" ref="icon">
          <chevrone-icon />
        </div>
      </section>
      <ul class="address__dropdown-list" ref="dropdown">
        <li class="address__dropdown-space"></li>
        <template v-for="(address, index) in addressList">
          <li
            class="address__dropdown-item"
            :key="'address-' + address.id"
            @click="setAddress(address)"
          >
            {{ address.label }}
          </li>
          <li
            class="address__dropdown-separator"
            v-if="index !== addressList?.length - 1"
            :key="'separator' + index"
          ></li>
        </template>
      </ul>
    </section>
    <section v-if="address.zoom" class="map__zoom" @click="zoom">
      <zoom-icon />
    </section>
    <button v-if="address.url" class="map__button" @click="getDirections">
      Проложить маршрут
    </button>
  </section>
</template>

<script>
import ZoomIcon from "@/assets/icons-new/zoom.vue";
import ChevroneIcon from "@/assets/icons-new/chevrone.vue";
import PointerIcon from "@/assets/icons-new/pointer.vue";

export default {
  name: "TheMap",
  components: {
    ZoomIcon,
    ChevroneIcon,
    PointerIcon,
  },
  data() {
    return {
      address: {
        id: 0,
        url: "https://yandex.ru/maps/1/moscow-and-moscow-oblast/?ll=37.555687%2C55.764709&mode=routes&rtext=~55.764708%2C37.555687&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgk3NzQ3ODI2MjUSStCg0L7RgdGB0LjRjywg0JzQvtGB0LrQstCwLCDQl9Cy0LXQvdC40LPQvtGA0L7QtNGB0LrQvtC1INGI0L7RgdGB0LUsIDE4LzIwIgoNBzkWQhUQD19C&z=16.82",
        zoom: "https://yandex.ru/maps/213/moscow/house/zvenigorodskoye_shosse_18_20/Z04YcwJlT00AQFtvfXt3dXtjbA==/?ll=37.555687%2C55.764709&z=16.82",
        img: "map-zvenigorodskoe",
        alias: "zvenigorodskoe_highway",
        label: "г. Москва, ул. Звенигородское шоссе д.18/20 корпус 1, 5 этаж",
      },
      addressList: [
        {
          id: 0,
          url: "https://yandex.ru/maps/1/moscow-and-moscow-oblast/?ll=37.555687%2C55.764709&mode=routes&rtext=~55.764708%2C37.555687&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgk3NzQ3ODI2MjUSStCg0L7RgdGB0LjRjywg0JzQvtGB0LrQstCwLCDQl9Cy0LXQvdC40LPQvtGA0L7QtNGB0LrQvtC1INGI0L7RgdGB0LUsIDE4LzIwIgoNBzkWQhUQD19C&z=16.82",
          zoom: "https://yandex.ru/maps/213/moscow/house/zvenigorodskoye_shosse_18_20/Z04YcwJlT00AQFtvfXt3dXtjbA==/?ll=37.555687%2C55.764709&z=16.82",
          img: "map-zvenigorodskoe",
          alias: "zvenigorodskoe_highway",
          label: "г. Москва, ул. Звенигородское шоссе д.18/20 корпус 1, 5 этаж",
        },
        {
          id: 1,
          url: "https://yandex.ru/maps/?ll=37.121478%2C55.812995&mode=routes&rtext=~55.812995%2C37.121478&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoyMTM2MDE5MDk4En_QoNC-0YHRgdC40Y8sINCc0L7RgdC60L7QstGB0LrQsNGPINC-0LHQu9Cw0YHRgtGMLCDQs9C-0YDQvtC00YHQutC-0Lkg0L7QutGA0YPQsyDQmNGB0YLRgNCwLCDQtNC10YDQtdCy0L3RjyDQm9C10YjQutC-0LLQviwgMjA5IgoNZnwUQhWBQF9C&z=16.82",
          zoom: "https://yandex.ru/maps/1/moscow-and-moscow-oblast/house/derevnya_leshkovo_209/Z04YdwVhTUIPQFtvfXRwc3VqYQ==/?ll=37.121478%2C55.812995&z=16",
          img: "map-leshkovo",
          alias: "leshkovo_village",
          label: "г. Истра, деревня Лешково, д. 209",
        },
        {
          id: 2,
          url: "https://yandex.ru/maps/2/saint-petersburg/?indoorLevel=1&ll=30.280748%2C60.029013&mode=routes&rtext=~60.029013%2C30.283214&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNDg1OTMzODg3EkzQoNC-0YHRgdC40Y8sINCh0LDQvdC60YIt0J_QtdGC0LXRgNCx0YPRgNCzLCDQoNC10L_QuNGJ0LXQstCwINGD0LvQuNGG0LAsIDE4IgoNBETyQRW1HXBC&z=16.74",
          zoom: "https://yandex.ru/maps/2/saint-petersburg/house/repishcheva_ulitsa_18/Z0kYdA9jS0QDQFhqfXxzeHxiZw==/?ll=30.284152%2C60.028904&z=16.71",
          img: "map-repishcheva",
          alias: "St_Petersburg_st_Repisheva",
          label: "г. Санкт-Петербург, ул. Репищева улица, 18",
        },
        {
          id: 3,
          url: "",
          zoom: "",
          img: "map-russia",
          alias: "regional_office_of_the_company",
          label: "Региональный офис компании",
        },
      ],
    };
  },
  created() {
    const fields = this.$store.getters?.offer?.fields;
    if (fields) {
      const officeList = fields.filter((field) => field?.alias === "office");
      if (officeList?.length) {
        const office = officeList[0];
        if (office) {
          const answers = office.directory.values.filter(
            (directory) => directory?.id === office.value.value
          );
          if (answers?.length) {
            const answer = answers[0];
            const address = this.addressList.filter(
              (address) => address.alias === answer?.alias
            );
            if (address?.length) {
              this.address = address[0];
            }
          }
        }
      }
    }
  },
  methods: {
    getImgUrl(name) {
      if (name) {
        var images = require.context("../assets/images/", false, /\.jpg$/);
        return images("./" + name + ".jpg");
      } else {
        return "";
      }
    },
    setAddress(address) {
      this.address = address;
      this.$refs.dropdown.classList.remove("dropdown--show");
      this.$refs.icon.classList.remove("icon-up");
      document.body.removeEventListener("click", this.removeClass);
    },
    removeClass(e) {
      const notClickClass = [
        "address__dropdown-separator",
        "address__dropdown-item",
        "address__dropdown-space",
        "address__dropdown-list",
        "address__dropdown-icon",
        "address",
        "address__wrapper",
        "map__address-box",
      ];

      if (
        typeof e.target.className === "string" &&
        !notClickClass.includes(e.target.className)
      ) {
        this.$refs.dropdown.classList.remove("dropdown--show");
        this.$refs.icon.classList.remove("icon-up");
        document.body.removeEventListener("click", this.removeClass);
      }
    },
    toogleAddress() {
      this.$refs.dropdown.classList.toggle("dropdown--show");
      this.$refs.icon.classList.toggle("icon-up");
      document.body.addEventListener("click", this.removeClass);
    },
    getDirections() {
      window.open(this.address.url, "_blank").focus();
    },
    zoom() {
      window.open(this.address.zoom, "_blank").focus();
    },
  },
};
</script>

<style lang="scss" scoped>
%text {
  font-family: "rotondac", sans-serif, Arial;
}

.map-box {
  position: relative;
  max-width: 1160px;
  width: 100%;
  height: 456px;
  border-radius: 10px;
  margin: $indent-60 auto;
  @media (max-width: 1200px) {
    margin: $indent-60 $indent-20;
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
  }
  .address__wrapper {
    position: absolute;
    left: 20px;
    top: 20px;
    @media (max-width: 570px) {
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  .address {
    width: 288px;
    @media (max-width: 570px) {
      font-size: 16px;
      line-height: 150%;
      width: 190px;
    }
    &__icon {
      width: 30px;
      height: 40px;
      margin-right: 25px;
      margin-left: 5px;
      @media (max-width: 380px) {
        display: none;
      }
    }
    &__dropdown-icon {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: $color-red;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 570px) {
        right: 20px;
      }
      svg {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
      }
    }
    &__dropdown-list {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      top: 100%;
      margin: -30px 0 0 0;
      padding: 0;
      background: $color-default;
      border-radius: 10px;
      overflow: auto;
      max-height: 0;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }
    &__dropdown-space {
      height: 30px;
      width: 100%;
    }
    &__dropdown-separator {
      list-style-type: none;
      height: 1px;
      width: calc(100% - 40px);
      margin: 0 20px;
      background: #cbcbcb;
    }
    &__dropdown-item {
      list-style-type: none;
      padding: $indent-20 $indent-30;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      cursor: pointer;
      @extend %text;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
  .map {
    width: 100%;
    height: auto;
    @media (max-width: 1200px) {
      width: auto;
      height: 100%;
    }
    &__zoom {
      position: absolute;
      left: 20px;
      bottom: 20px;
      width: 80px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 0px 22px rgba(0, 51, 160, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media (max-width: 700px) {
        display: none;
      }
      svg {
        width: 52px;
        height: 52px;
      }
    }
    &__button {
      position: absolute;
      right: 20px;
      top: 20px;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      padding: $indent-20 $indent-40;
      border: none;
      outline: none;
      background: radial-gradient(50% 50% at 50% 50%, #0077c8 0%, #0033a0 100%);
      box-shadow: 0px 0px 22px rgba(0, 51, 160, 0.1);
      border-radius: 10px;
      color: $color-default;
      cursor: pointer;
      @extend %text;
      @media (max-width: 890px) {
        top: calc(100% + 20px);
        right: 50%;
        transform: translateX(50%);
        max-width: 340px;
        width: 100%;
      }
    }
    &__address-box {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      background: #ffffff;
      box-shadow: 0px 0px 22px rgba(0, 51, 160, 0.1);
      border-radius: 10px;
      padding: $indent-20;
      cursor: pointer;
      height: 88px;
      @extend %text;
    }
  }
}
.dropdown--show {
  max-height: max-content !important;
}
.icon-up {
  svg {
    transform: rotate(270deg) !important;
  }
}
</style>
