import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const baseURL = process.env.VUE_APP_API;
const http = axios.create({ baseURL });

export default new Vuex.Store({
  state: {
    offer: null,
    pending: false,
    showModalAccept: false,
    showModalReject: false,
  },
  getters: {
    showModalAccept: (s) => s.showModalAccept,
    showModalReject: (s) => s.showModalReject,
    offer: (s) => s.offer,
    pending: (s) => s.pending,
  },
  mutations: {
    offer(state, value) {
      state.offer = value;
    },
    pending(state, value) {
      state.pending = value;
    },
    showModalAccept(state, value) {
      state.showModalAccept = value;
    },
    showModalReject(state, value) {
      state.showModalReject = value;
    },
  },
  actions: {
    sendComment(_, { fieldId, offerId, token, comment }) {
      return new Promise((resolve, reject) => {
        http
          .post(`/candidates/field-values?token=${token}`, {
            field_template_id: fieldId,
            offer_id: offerId,
            value: comment,
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    responseOffer({ state, commit }, { offerId, token, response }) {
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          http
            .get(
              `/candidate/response/${offerId}?token=${token}&response=${response}`
            )
            .then(() => {
              resolve(true);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          // code
        }
      });
    },
    async offer({ state, commit }, { id, token, isAdministration }) {
      if (!state.pending) {
        let query = "";

        if (isAdministration) {
          query += "&viewer=administration";
        }

        commit("pending", true);
        const response = await http.get(
          `/candidates/offers/${id}?token=${token}${query}`
        );
        commit("offer", response.data.data);
        commit("pending", false);
        return response.data.data;
      }
    },
  },
  modules: {},
});
