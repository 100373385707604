<template>
  <aside>
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_357_1204)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32 6C25.1044 6 18.4912 8.73928 13.6152 13.6152C8.73928 18.4912 6 25.1044 6 32C6 38.8956 8.73928 45.5088 13.6152 50.3848C18.4912 55.2607 25.1044 58 32 58C38.8956 58 45.5088 55.2607 50.3848 50.3848C55.2607 45.5088 58 38.8956 58 32C58 25.1044 55.2607 18.4912 50.3848 13.6152C45.5088 8.73928 38.8956 6 32 6ZM0 32C0 23.5131 3.37142 15.3737 9.37258 9.37258C15.3737 3.37142 23.5131 0 32 0C40.4869 0 48.6263 3.37142 54.6274 9.37258C60.6286 15.3737 64 23.5131 64 32C64 40.4869 60.6286 48.6263 54.6274 54.6274C48.6263 60.6286 40.4869 64 32 64C23.5131 64 15.3737 60.6286 9.37258 54.6274C3.37142 48.6263 0 40.4869 0 32Z"
          fill="currentColor"
          fill-opacity="0.05"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29 3C29 2.20435 29.3161 1.44129 29.8787 0.87868C30.4413 0.31607 31.2044 0 32 0C40.4869 0 48.6263 3.37142 54.6274 9.37258C60.6286 15.3737 64 23.5131 64 32C64 32.7956 63.6839 33.5587 63.1213 34.1213C62.5587 34.6839 61.7956 35 61 35C60.2044 35 59.4413 34.6839 58.8787 34.1213C58.3161 33.5587 58 32.7956 58 32C58 25.1044 55.2607 18.4912 50.3848 13.6152C45.5088 8.73928 38.8956 6 32 6C31.2044 6 30.4413 5.68393 29.8787 5.12132C29.3161 4.55871 29 3.79565 29 3Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_357_1204">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </aside>
</template>

<script>
export default {
  name: "loading-simple",
};
</script>

<style lang="scss" scoped>
aside {
  animation: rotate 0.5s linear infinite;
  svg {
    width: 100%;
    height: 100%;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
