<template>
  <section class="fifth-section">
    <div class="label">Что ждет дальше</div>
    <div class="step-box">
      <div class="step">
        <div class="step__icon"><span>1</span></div>
        <div>
          <div class="step__label">Примите оффер</div>
          <div class="step__description">
            Нажмите кнопку “Принять предложение” в этом письме
          </div>
        </div>
      </div>
      <div class="step">
        <div class="step__icon"><span>2</span></div>
        <div>
          <div class="step__label">Ждите письма</div>
          <div class="step__description">
            Наша коллега из HR отдела напишет Вам по организационным моментам и
            необходимым для оформления документам.
          </div>
        </div>
      </div>
      <div class="step">
        <div class="step__icon"><span>3</span></div>
        <div>
          <div class="step__label">Приходите в офис</div>
          <div class="step__description">
            В согласованный день и время мы будем ждать Вас для оформления. Все
            инструкции будут высланы Вам заблаговременно сотрудником HR отдела.
          </div>
        </div>
      </div>
      <div class="step">
        <div class="step__icon">
          <span style="transform: translate(-1px, 2px)">4</span>
        </div>
        <div>
          <div class="step__label">Первый рабочий день</div>
          <div class="step__description">
            Мы подготовим техническое оборудование, рабочее место и пропуск.
            Будем рады видеть Вас в нашей команде!
          </div>
        </div>
      </div>
      <div class="step"></div>
      <div class="step"></div>
      <div class="step"></div>
      <div class="step"></div>
    </div>
    <div class="label">Наш офис находится по адресу:</div>
    <the-map />
  </section>
</template>

<script>
import TheMap from "@/components/TheMap.vue";

export default {
  name: "FifthSection",
  components: { TheMap },
};
</script>

<style lang="scss" scoped>
%text {
  font-family: "rotondac", sans-serif, Arial;
}
.fifth-section {
  position: relative;
  width: 100%;
  z-index: 1;
}
.label {
  max-width: 960px;
  font-weight: 700;
  font-size: $font-size-40;
  line-height: 125%;
  letter-spacing: 0.05em;
  margin-top: $indent-120;
  margin-right: auto;
  margin-left: auto;
  color: $color-default;
  text-align: center;
  @extend %text;
  @media (max-width: 600px) {
    font-size: 21px;
    margin-top: $indent-60;
  }
}
.step-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: $indent-60;
  @media (max-width: 1200px) {
    margin-left: 0;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    margin-left: -40px;
    @media (max-width: 1200px) {
      margin-left: 0px;
    }
  }
  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: $indent-60 $indent-20 $indent-40;
    background: green;
    width: 260px;
    margin-left: $indent-40;
    border-radius: 26px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.04) -4.07%,
      rgba(255, 255, 255, 0) 62.96%
    );
    box-shadow: 0px -26px 30px rgba(0, 0, 0, 0.05);
    @media (max-width: 1200px) {
      margin-left: $indent-20;
    }
    @media (max-width: 560px) {
      margin-left: 0;
      width: calc(100% - 40px);
      padding: 10px 30px 30px 10px;
      flex-direction: row;
      margin-top: 30px;
      &:first-child {
        margin-top: 0px;
      }
    }
    &:empty {
      height: 0;
      padding: 0;
      border: none;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0;
    }
    &__icon {
      position: relative;
      background: $color-pink;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      border-radius: 50%;
      color: $color-default;
      font-size: $font-size-28;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 28px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      @extend %text;
      @media (max-width: 560px) {
        position: static;
        transform: none;
        margin-right: 30px;
      }
      span {
        position: absolute;
        transform: translate(0px, 2px);
      }
    }
    &__label {
      color: $color-primary;
      font-size: $font-size-20;
      text-align: center;
      font-weight: 700;
      @extend %text;
      @media (max-width: 560px) {
        font-size: 16px;
        text-align: left;
        margin-top: 8px;
      }
    }
    &__description {
      text-align: center;
      margin-top: $indent-20;
      color: $color-default;
      font-size: $font-size-16;
      font-weight: 400;
      @extend %text;
      @media (max-width: 560px) {
        text-align: left;
        margin-top: 10px;
        font-size: 13px;
      }
    }
  }
}
</style>
