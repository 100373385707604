import Vue from 'vue'
import Landing from './Landing.vue'
import store from './store'

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(Landing)
}).$mount('#app')
